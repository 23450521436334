import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const WithLayout = ({ children }) => (
  <div className="default-layout">
     <Navbar />
    <main>{children}</main>
    <Footer />
  </div>
);

export default WithLayout;