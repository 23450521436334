import { SHOW_TOSTER } from "./type";



export const viewToster= (payload) => {
  // console.log("viewToster payload",payload);
  
  return {
    type: SHOW_TOSTER,
    payload,
  };
};
