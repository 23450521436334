
import api from "../api";
import { GET_FOLLOW_INTEREST,  POST_INTEREST, GET_FOLLOWING_DATA, POST_FOLLOWING_DATA, SELECTED_INTERESTED_DATA, GET_TAGS_DATA, POST_TOPICS_TAGS_DATA, GET_SEARCH_TAGS_DATA,GET_MODEL_INTEREST_DATA,GET_TOP_STORIES,ACCOUNT_UPDATE,GET_ACCOUNT,GET_TRANDING, SELECTED_TAGS_ISBOREDED } from "./type";

export const topStories=(payload)=>async (dispatch)=>{
    try {
        const response = await api.get(`/user/top-stories?rows=${payload??""}`)
        if (response?.status === 200) {
            dispatch({ type: GET_TOP_STORIES, payload: response?.data });
            
        } else {

        }
    } catch (error) {
        
    }
}
export const trandingData=(payload)=>async (dispatch)=>{
    try {
        const response = await api.get(`/user/trending?rows=${payload??""}`)
        if (response?.status === 200) {
            dispatch({ type: GET_TRANDING, payload: response?.data });
            
        } else {

        }
    } catch (error) {
        
    }
}
export const getFollowYourInterest = () => async (dispatch) => {
    try {
        const response = await api.get(`/user/topics`)
        // console.log("getFollowYourInterest", response);
        if (response?.status === 200) {


            dispatch({ type: GET_FOLLOW_INTEREST, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}
export const postInterest = (payload) => async (dispatch) => {
    // console.log("postInterestpayload",payload);

    try {
        const response = await api.post("/user/add-interest", { interests: payload });
        if (response?.status === 200) {

            dispatch({ type: POST_INTEREST, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });

// if(localStorage.getItem(""))
            // navigate("/followyourinterest")
        } else {
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "error", message: response?.data?.msg },
            // });
        }
        // window.location.href = "/following";
    } catch (error) {
        // console.log("postInteresterror",error);

        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}

export const getFollowingData = () => async (dispatch) => {
    try {
        const response = await api.get(`/user/get-all-interests`)
        if (response?.status === 200) {
            dispatch({ type: GET_FOLLOWING_DATA, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}

export const followingDataPost = (payload) => async (dispatch) => {
    try {
        const response = await api.post("/user/add-interest", { interests: payload });
        if (response?.status === 200) {

            dispatch({ type: POST_FOLLOWING_DATA, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });

        } else {
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "error", message: response?.data?.msg },
            // });
        }
        // window.location.href="/following";
    } catch (error) {
        // console.log("postInteresterror",error);

        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }

}

export const getSelectedInterest = (payload) => async (dispatch) => {
    // console.log("selectedInterestpayload", payload);
    try {
        const response = await api.get(`/user/get-interest/${payload}`)
        if (response?.status === 200) {
            dispatch({ type: SELECTED_INTERESTED_DATA, payload: response?.data });

        } else {
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "error", message: response?.data?.msg },
            // });
        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}
export const getHashTags = (payload) => async (dispatch) => {
    try {
        const response = await api.get(`/user/get-tag/${payload}`)
        if (response?.status === 200) {


            dispatch({ type: GET_TAGS_DATA, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}

export const sendSelectedTags = (payload) => async (dispatch) => {
    // console.log("sendSelectedTagspayload", payload);
    try {
        const response = await api.post("/user/add-tags/", payload);
        if (response?.status === 200) {

            dispatch({ type: POST_TOPICS_TAGS_DATA, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });

        } else {
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "error", message: response?.data?.msg },
            // });
        }
    } catch (error) {

        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }

}

export const searchTagData = (id, value) => async (dispatch) => {
    // console.log("searchTagDatapayload", id, "value", value);

    try {
        const response = await api.get(`/user/get-tag/${id}?search=${value}`)
        if (response?.status === 200) {
            dispatch({ type: GET_SEARCH_TAGS_DATA, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}
export const modelshowInterest=()=>async(dispatch)=>{
    try {
        const response = await api.get(`/user/selected-topics`)
        if (response?.status === 200) {
            
        dispatch({ type: GET_MODEL_INTEREST_DATA, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}
export const modelshowTopic=()=>async(dispatch)=>{
    // try {
    //     const response = await api.get(`/user/selected-topics`)
    //     if (response?.status === 200) {
            
    //     dispatch({ type: GET_MODEL_INTEREST_DATA, payload: response?.data });
    //         dispatch({
    //             type: SHOW_TOSTER,
    //             payload: { type: "success", message: response?.data?.msg },
    //         });
    //     } else {

    //     }
    // } catch (error) {
    //     dispatch({
    //         type: SHOW_TOSTER,
    //         payload: { type: "error", message: error?.response?.data?.msg },
    //     });
    // }
}
export const accountUpdate=(payload)=>async(dispatch)=>{
  try {
        const response = await api.put(`/user/edit-profile`,payload)
        if (response?.status === 200) {
            
        dispatch({ type: ACCOUNT_UPDATE, payload: response?.data });
        // dispatch({ type: GET_ACCOUNT, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}
export const getAccountDetails=()=>async(dispatch)=>{
// /user/get-profile
try {
    const response = await api.get(`/user/get-profile`)
    // console.log("getAccountDetails",response);
    
    if (response?.status === 200) {
        // console.log("getAccountDetails",response);
        
    dispatch({ type: GET_ACCOUNT, payload: response?.data });
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "success", message: response?.data?.msg },
        // });
    } else {

    }
} catch (error) {
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
}
}
export const selectedTags=(payload)=>async(dispatch)=>{
    try {
        const response = await api.get(`/user/selected-tags/${payload}`)
        // console.log("selectedTags",response);
        
        if (response?.status === 200) {
            // console.log("getAccountDetails",response);
            
        dispatch({ type: SELECTED_TAGS_ISBOREDED, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {
    
        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}