import { combineReducers } from "redux";

import authReducer from "./authReducer";
import toasterReducer from "./toasterReducer";
import publicReducer from "./publicReducer";
import privateApiReducer from "./privateApiReducer";

const appReducers = combineReducers({
   
    auth:authReducer,
    toasterData:toasterReducer,
    publicReducer:publicReducer,
    privateReducer:privateApiReducer,
});


const rootReducer = (state, action) => {

    return appReducers(state, action);
  };
export default rootReducer;