import axios from 'axios';
// import { store } from '../redux/store'; // Import your Redux store
// import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

export const BASE_URL_PP = process.env.REACT_APP_BASE_URL || "https://pp-api.extcons.xyz/api/v1/";
// export const BASE_URL_PP = process.env.REACT_APP_BASE_URL || "http://192.168.29.154:5000/api/v1/";
// export const BASE_URL_PP ="https://pp-api.extcons.xyz/api/v1/" || "http://192.168.29.154:5000/api/v1/"
const api = axios.create({
  baseURL: BASE_URL_PP,
  headers: {
    'Content-Type': 'application/json',
  },
  maxBodyLength: Infinity,
});

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    // const state = store.getState();
    const token =  localStorage.getItem("accessToken");
    // console.log("token",token);
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const navigate = useNavigate();
    const originalRequest = error.config;
    if (
      (error?.response?.status === 403 || error?.response?.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        // const state = store.getState();
        const token =  localStorage.getItem("accessToken"); 
        // console.log("tokenapi",token);
        
        if (token) {
          originalRequest.headers.Authorization = `Bearer ${token}`;
        }
        return axios(originalRequest);
      } catch (refreshError) {
        // Handle refresh token error
        localStorage.clear();
        // navigate("/login");
      }
    }
    return Promise.reject(error);
  }
);

export default api;
