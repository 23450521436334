import { useState } from "react";

function Subscription() {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleCheck = (plan) => {
    setSelectedPlan(plan);
  };

  return (
    <div className="p-3 md:p-5">
      <div className="text-[#1A1A1A] cursor-pointer flex items-center gap-2 text-lg md:text-xl lg:text-2xl font-normal">
        Plans & Pricing
      </div>

      <hr className="border-b-1 md:border-none mt-3 md:mt-0 font-semibold"/>
          

      <div className="flex flex-row gap-5 mt-4">
        {/* Plan 1 */}
        <div
          className="flex bg-white mb-2 rounded-xl cursor-pointer flex-col"
          onClick={() => handleCheck("regular")}
        >
          <div className="max-h-[400px] max-w-[500px] md:max-h-[500px] h-[180px] w-[180px] md:h-[200px] md:w-[200px] md:max-w-[600px] flex flex-col">
            <div className="flex flex-row text-center justify-center py-3 uppercase text-[12px] font-semibold opacity-70">
              REGULAR
            </div>
            <hr className="w-[10rem] mx-auto border-t border-gray-200" />

            <div className="p-4 justify-start flex flex-col">
              <div className="uppercase flex flex-row gap-1 text-xs font-normal">
                1-month
              </div>
              <div className="text-[10px] font-semibold ">
                <span className=" text-lg">$ 20</span>{" "}
                <span className="opacity-60 ">monthly</span>
              </div>
            </div>

            <div className=" mt-auto pb-4">
              <div className="flex justify-center text-center">
                <input
                  type="radio"
                  id="regular"
                  name="plan"
                  checked={selectedPlan === "regular"}
                  onChange={() => handleCheck("regular")}
                  className="hidden"
                />
                <label
                  htmlFor="regular"
                  className={`cursor-pointer rounded-full border-2 border-gray-300 w-8 h-8 flex items-center justify-center ${
                    selectedPlan === "regular"
                      ? "border-green-500 bg-green-500"
                      : ""
                  }`}
                >
                  {selectedPlan === "regular" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 00-1.414-1.414L7.5 12.672 4.707 9.879a1 1 0 10-1.414 1.414l3.5 3.5a1 1 0 001.414 0l8-8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Plan 2 */}
        <div
          className="flex bg-white mb-2 rounded-xl cursor-pointer flex-col"
          onClick={() => handleCheck("bestvalue")}
        >
          <div className="max-h-[400px] max-w-[500px] md:max-h-[500px] h-[150px] w-[180px] md:h-[200px] md:w-[200px] md:max-w-[600px] flex flex-col">
            <div className="flex flex-row text-center justify-center py-3 uppercase text-[12px] font-semibold opacity-70">
              BEST VALUE
            </div>
            <hr className="w-[10rem] mx-auto border-t border-gray-200" />

            <div className="p-4 justify-start flex flex-col">
              <div className="uppercase flex flex-row gap-1 text-xs font-normal">
                1-year
              </div>
              <div className="text-[10px] font-semibold ">
                <span className=" text-lg">$ 8</span>{" "}
                <span className="opacity-60 ">monthly</span>
              </div>
            </div>

            <div className=" mt-auto pb-4">
              <div className="flex justify-center text-center">
                <input
                  type="radio"
                  id="bestvalue"
                  name="plan"
                  checked={selectedPlan === "bestvalue"}
                  onChange={() => handleCheck("bestvalue")}
                  className="hidden"
                />
                <label
                  htmlFor="bestvalue"
                  className={`cursor-pointer rounded-full border-2 border-gray-300 w-8 h-8 flex items-center justify-center ${
                    selectedPlan === "bestvalue"
                      ? "border-green-500 bg-green-500"
                      : ""
                  }`}
                >
                  {selectedPlan === "bestvalue" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 00-1.414-1.414L7.5 12.672 4.707 9.879a1 1 0 10-1.414 1.414l3.5 3.5a1 1 0 001.414 0l8-8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
