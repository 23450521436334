import { useState } from "react";
import { FaSearch } from "react-icons/fa";

function PollingTable() {
  const date = new Date("2024-08-12T14:55:00");
  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate =
    date.toLocaleString("en-US", options).toUpperCase().replace(",", "") +
    " AT " +
    date
      .toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .toUpperCase();

  return (
    <div className="container mx-auto py-4 border rounded-md border-gray-200">
      {/* Search and Dropdown */}
      <div className="flex flex-col md:flex-row md:justify-between items-center mb-4 px-4">
        <div className="flex flex-col mb-4 md:mb-0">
          <div className="text-lg font-semibold pb-1">1,372 polls</div>
          <div className="text-sm text-gray-500">
            Latest Poll: {formattedDate}
          </div>
        </div>

        {/* Dropdown Menu */}
        <div className="flex items-center gap-2 mb-4 md:mb-0">
          <div className="text-sm font-medium">POLITICIAN</div>
          <select className="bg-[#E2E7EE] rounded-xl px-3 py-1.5 text-sm">
            <option className="text-black" value="All">
              All
            </option>
            <option className="text-black" value="jeo baden">
              jeo baden
            </option>
          </select>

          {/* Search Bar */}
          <div className="bg-gray-100 rounded-lg flex items-center gap-2 px-3 py-1.5">
            <FaSearch className="text-gray-600" />
            <div className="h-5 w-px bg-gray-300" />
            <input
              type="search"
              placeholder="Search Keyword"
              className="border-none outline-none text-xs bg-transparent rounded-md py-1 px-2 placeholder-gray-400"
            />
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white ">
          <thead className="bg-[#ebeef0]">
            <tr className="text-[12px] ">
              <th className="px-4 py-2 border-b border-gray-200 text-left">
                DATES
              </th>
              <th className="px-4 py-2 border-b border-gray-200 text-left">
                SAMPLE
              </th>
              <th className="px-4 py-2 border-b border-gray-200 text-left">
                POLLSTER
              </th>
              <th className="px-4 py-2 border-b border-gray-200 text-left">
                ACCURACIES
              </th>
              <th className="px-4 py-2 border-b border-gray-200 text-left">
                SPONSOR
              </th>
              <th className="px-4 py-2 border-b border-gray-200 border-l-2 text-left">
                RESULT %
              </th>
              <th className="px-4 py-2 border-b border-gray-200 text-left">
                NET RESULT
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200 text-[12px]">
              <td className="px-4 py-2">Data 1</td>
              <td className="px-4 py-2">Data 2</td>
              <td className="px-4 py-2">Data 3</td>
              <td className="px-4 py-2">Data 1</td>
              <td className="px-4 py-2">Data 1</td>
              <td className="px-4 py-2  border-l-2">
                {" "}
                <div className="flex flex-row">
                  Trump
                  <div className="grid mx-2 place-items-center overflow-x-scroll  lg:overflow-visible">
                    <div className="flex flex-col gap-4 w-max">
                      <div className="flex divide-x divide-[#E60800] row">
                        <button
                          className="align-middle select-none font-sans  text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-2 rounded-lg bg-[#E60800] text-white shadow-md  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-r-none rounded-l-none "
                          type="button"
                        >
                          12
                        </button>

                        <button
                          className="align-middle select-none font-sans  text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-2 rounded-lg bg-[#4252A8] text-white shadow-md    focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-l-none rounded-r-none"
                          type="button"
                        >
                          14
                        </button>
                      </div>
                    </div>
                  </div>
                  Biden{" "}
                </div>
              </td>
              <td className="px-4 py-2">Data 3</td>
            </tr>
            <tr className="border-b border-gray-200 text-[12px]">
              <td className="px-4 py-2">Data 4</td>
              <td className="px-4 py-2">Data 5</td>
              <td className="px-4 py-2">Data 6</td>
              <td className="px-4 py-2">Data 1</td>
              <td className="px-4 py-2">Data 1</td>
              <td className="px-4 py-2  border-l-2">
                {" "}
                <div className="flex flex-row">
                  Trump
                  <div className="grid mx-2 place-items-center overflow-x-scroll  lg:overflow-visible">
                    <div className="flex flex-col gap-4 w-max">
                      <div className="flex divide-x divide-[#E60800] row">
                        <button
                          className="align-middle select-none font-sans  text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-2 rounded-lg bg-[#E60800] text-white shadow-md  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-r-none rounded-l-none "
                          type="button"
                        >
                          12
                        </button>

                        <button
                          className="align-middle select-none font-sans  text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-2 rounded-lg bg-[#4252A8] text-white shadow-md    focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-l-none rounded-r-none"
                          type="button"
                        >
                          14
                        </button>
                      </div>
                    </div>
                  </div>
                  Biden{" "}
                </div>
              </td>
              <td className="px-4 py-2">Data 3</td>
            </tr>
            <tr className="border-b border-gray-200 text-[12px]">
              <td className="px-4 py-2">Data 7</td>
              <td className="px-4 py-2">Data 8</td>
              <td className="px-4 py-2">Data 9</td>
              <td className="px-4 py-2">Data 1</td>
              <td className="px-4 py-2">Data 2</td>

              <td className="px-4 py-2  border-l-2">
                {" "}
                <div className="flex flex-row">
                  Trump
                  <div className="grid mx-2 place-items-center overflow-x-scroll  lg:overflow-visible">
                    <div className="flex flex-col gap-4 w-max">
                      <div className="flex divide-x divide-[#E60800] row">
                        <button
                          className="align-middle select-none font-sans  text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-2 rounded-lg bg-[#E60800] text-white shadow-md  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-r-none rounded-l-none "
                          type="button"
                        >
                          12
                        </button>

                        <button
                          className="align-middle select-none font-sans  text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-2 rounded-lg bg-[#4252A8] text-white shadow-md    focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-l-none rounded-r-none"
                          type="button"
                        >
                          14
                        </button>
                      </div>
                    </div>
                  </div>
                  Biden{" "}
                </div>
              </td>
              <td className="px-4 py-2">Data 3</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PollingTable;
