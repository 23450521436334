import { useState } from "react";
import ChipWithStatus from "../ChipWithStatus";
import staricon from "../../assets/icons/Path 23128.svg";

function StateComp() {
  const stateData = [
    {
      id: 1,
      name: "Alabama",
      abbreviation: "AL",
      capital: "Montgomery",
      population: 4903185,
      predictionValue: 9,
      keyStatus: true,
      color: "success",
      label: "check",
    },
    {
      id: 2,
      name: "California",
      abbreviation: "CA",
      capital: "Sacramento",
      population: 39512223,
      predictionValue: 12,
      keyStatus: false,
    },
    {
      id: 3,
      name: "Florida",
      abbreviation: "FL",
      capital: "Tallahassee",
      population: 21477737,
      predictionValue: 7,
      keyStatus: true,
      color: "orange",
      label: "check",
    },
  ];

  return (
    <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5">
      <div className="max-h-[500px]">
        <div className="flex flex-row items-center justify-between gap-10 md:gap-0">
          <div className="font-semibold text-[16px] mb-2">States</div>
          <button className=" p-2 rounded-full flex gap-2 items-center justify-end cursor-pointer border bg-gray-200 w-1/3 md:w-32">
            <div className="flex flex-row">
              {/* <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="relative w-7 h-4 bg-white rounded-full peer-focus:outline-black peer-checked:after:translate-x-full peer-checked:bg-[#FF3D00] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-black after:border after:rounded-full after:h-3 after:w-3 after:transition-all"></div>
              </label> */}
<div className="switch-container">
  <input type="checkbox" id="toggle" className="switch-checkbox" />
  <label htmlFor="toggle" className="switch-label"></label>
</div>

              <div className="text-[10px] ms-2">Key States Only</div>
            </div>
          </button>
        </div>

        {stateData.map((state) => (
          <div key={state.id}>
            <div className="py-4  border-b-2">
              <div className="justify-between flex flex-row ">
                <div className="flex flex-row text-center gap-2">
                  <div className="text-[14px] text-gray-700">
                    {state?.name} <span className="text-gray-500">(9)</span>
                  </div>
                  <div className="text-[12px]">
                    {state.keyStatus && (
                      <div
                        className="relative grid items-center px-2 py-1 font-sans text-[10px] font-semibold text-white rounded-md select-none whitespace-nowrap "
                        style={{ backgroundColor: "#FFA167" }}
                      >
                        <div className="absolute w-4 h-5 top-2/4 left-1 -translate-y-2/4 items-center">
                          <img
                            src={staricon}
                            alt="star"
                            className="mx-auto mt-1 block h-[10px] w-[10px]  text-white"
                          />
                        </div>
                        <span className="ml-4">Key States</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <label className="inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" />
                    <div className="relative w-7 h-4 bg-gray-200 rounded-full peer-focus:outline-none peer-checked:after:translate-x-full peer-checked:bg-[#FF3D00] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StateComp;
