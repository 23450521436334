import React, { useEffect, useState } from "react";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import SecHead from "../SectionHeader/SecHead";
import { useDispatch, useSelector } from "react-redux";
import { getPublicLocalNews } from "../../redux/actions/publicAction";
import moment from "moment";
import { Link } from "react-router-dom";

const LocalNewsHomeComp = () => {
  const dispatch = useDispatch();

  const { publicLocalNews } = useSelector((state) => state.publicReducer);
  // const {topStoriesData}=useSelector((state)=>state.privateReducer);

  // const isVerified = localStorage.getItem("accessToken");

  useEffect(() => {
    // if (!isVerified) {
    dispatch(getPublicLocalNews(3));
    // } else {
    //   // dispatch(topStories())
    // }
  }, []);

  // console.log("publicLocalNews",publicLocalNews);

  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }
  return (
    <div className="bg-white w-full p-3 md:p-5 px-4 rounded-lg">
      <SecHead title={"Local News"} redirectLink="/local-news" />

      <div className=" ">
        {Array.isArray(publicLocalNews) &&
          publicLocalNews.map((item, index) => (
            <Link
              // to={`/local-news-details/${item?.uuid}`}
              to={`/local-news/${item?.slug}`}
            >
              <div
                key={item?.id}
                className={`flex flex-row items-start  gap-4 md:gap-4 py-2 border-b border-gray-300   ${
                  index === publicLocalNews.length - 1
                    ? "border-none"
                    : "border-b"
                } justify-between`}
              >
                <div className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90">
                  <CategoryPolls
                    title={item?.category?.name}
                    id={item?.category?.id}
                  />
                  <span className="font-medium text-[14px] text-[#1A1A1A] leading-[18px]  tracking-normal opacity-95">
                    {item?.title}
                  </span>

                  <span className="date_shown mt-4">
                    {" "}
                    {moment(item[0]?.createdAt).format("MMMM D, YYYY")}
                  </span>
                </div>

                <img
                  src={item?.file?.[0]?.url ?? ""}
                  alt={item?.fileId}
                  className="  w-20 mt-2 md:w-20 h-auto object-cover rounded-md "
                />
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default LocalNewsHomeComp;
