import { useEffect, useState } from "react";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import { Link } from "react-router-dom";

function Subscriptions() {
  const subscriptionData = [
    {
      id: 1,
      amount: "$ 8",
      plan: "1-year",
      duration: "Yearly",
      plan_started: "12th Jul, 2024",
      expires_on: "11th Jul, 2025",
    },
    {
      id: 2,
      amount: "$ 10",
      plan: "Month",
      duration: "Monthly",
      plan_started: "12th Jul, 2024",
      expires_on: "11th Jul, 2025",
    },
  ];

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="bg_home flex flex-col pb-8 bg-white md:bg-[#F7F8FA]">
        <div className="bg_home px-4 md:px-40 flex flex-col pb-14">
          <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 mt-6">
          <div className="md:hidden">  <PreSectionHeader title={"Subscriptions"} /></div>
<div className="hidden text-[#1A1A1A] cursor-pointer flex items-center gap-2 text-lg md:text-xl lg:text-2xl font-normal">
Subscriptions
</div>
{/* toast warning message */}
<div className="my-4">
  <div className="border rounded-lg w-full border-[#FF9024] bg-[#FFF7EE]">
    <div className="p-3">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row p-2">
        <div className="me-4 mb-4">
  <div className="border-[#FF9024] border-4 rounded-full bg-[#FFF7EE] p-2 w-4 h-4">
  
  </div>
</div>

<div className="flex flex-col">
  <div className="text-[14px]">No active plan!</div>
  <div className="text-[12px]">Please buy a new plan to activate your subscription.</div>
</div>

        </div>
        <div>
          <button type="button" className="bg-[#000] text-[#fff] p-1 px-2 rounded-lg">
          Buy Plan
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

           

            <div className="items-start gap-5 my-4 p-4 border-2 rounded-lg">
              <div className="flex flex-row gap-2 opacity-90">
                <div className="uppercase  text-sm font-medium ">
                  best value
                </div>
                {/* <div className="bg-[#E0B8FF] rounded-lg py-1 px-2  text-[10px]">
                  <button type="btn ">Current Plan</button>
                </div> */}
                <div className="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-900  rounded-md select-none whitespace-nowrap bg-green-500/20">
                  <span className="">Current Plan</span>
                </div>
              </div>

              {subscriptionData.map((subscription) => (
                <div className="overflow-y-auto scrollbar-hide">
                  {
                    isMobile ? (
                      // mobile responsive
                      <div className="grid gap-4 grid-cols-2 gap-3">
                        {/* <div className="flex items-center gap-2 py-2 col-span-1"> */}
                        <div className="flex flex-col py-2">
                          <div className="uppercase text-xs font-normal">
                            {subscription?.plan}
                          </div>
                          <div className="text-[10px] font-semibold">
                            <span className="text-lg">
                              {subscription?.amount}
                            </span>{" "}
                            <span className="opacity-60">
                              {subscription?.duration}
                            </span>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="hidden md:block h-auto w-[1px] bg-gray-300 my-4 mt-3" /> */}
                        <div className="flex items-center gap-2 col-span-1 "></div>
                        <div className="flex items-center gap-2 col-span-1 ">
                          <div className="flex flex-col">
                            <div className="flex flex-row gap-1">
                              <div className="opacity-90 text-[10px]">
                                Plan Started
                              </div>
                            </div>
                            <div className="text-[10px] font-semibold">
                              <span className="text-xs">
                                {subscription?.plan_started}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* <div className="hidden md:block h-auto w-[1px] bg-gray-300 my-4 mt-3" /> */}

                        <div className="flex items-center gap-2 col-span-1">
                          <div className="flex flex-col">
                            <div className="flex flex-row gap-1">
                              <div className="opacity-90 text-[10px]">
                                Expires on
                              </div>
                            </div>
                            <div className="text-[10px] font-semibold">
                              <span className="text-xs">
                                {subscription?.expires_on}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row gap-2 col-span-2">
                          <div className="flex flex-row gap-4 w-full justify-between">
                            <button
                              type="button"
                              className="flex-1 flex items-center justify-center rounded-xl py-2 text-[12px] font-normal border border-2 outline-[#7F8FA4] outline-offset-1"
                            >
                              <span className="px-3">Cancel Subscription</span>
                            </button>
                            <Link
                              to="/plansprice"
                              type="button"
                              className="flex-1 flex items-center justify-center rounded-xl py-2 text-[12px] font-normal border border-2 outline-[#7F8FA4] outline-offset-1"
                            >
                              Upgrade Plan
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // web view start
                      <div className="flex flex-col md:flex-row  md:gap-3 md:py-2">
                        <div
                          key={subscription?.id}
                          className="flex  items-center gap-2 py-2 w-[24%]"
                        >
                          <div className="justify-start flex flex-col">
                            <div className="uppercase flex flex-row gap-1 text-xs font-normal">
                              {subscription?.plan}
                            </div>
                            <div className="text-[10px] font-semibold ">
                              <span className=" text-lg">
                                {subscription?.amount}
                              </span>{" "}
                              <span className="opacity-60 ">
                                {subscription?.duration}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="h-auto w-[1px] bg-gray-300 my-4 mt-3" />
                        <div className="flex  items-center gap-2 md:px-6  w-[24%]">
                          <div className=" justify-start flex flex-col">
                            <div className=" flex flex-row gap-1 ">
                              <div className="opacity-90 text-[10px]">
                                Plan Started
                              </div>
                            </div>
                            <div className="text-[10px] font-semibold ">
                              <span className=" text-xs">
                                {subscription?.plan_started}
                              </span>{" "}
                              {/* <span className="opacity-60 ">monthly</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="h-auto w-[1px] bg-gray-300 my-4 mt-3" />
                        <div className="flex items-center gap-2 md:px-6  w-[24%]">
                          <div className=" justify-start flex flex-col">
                            <div className=" flex flex-row gap-1">
                              <div className="opacity-90 text-[10px]">
                                Expires on
                              </div>
                            </div>
                            <div className="text-[10px] font-semibold ">
                              <span className=" text-xs">
                                {subscription?.expires_on}
                              </span>{" "}
                              {/* <span className="opacity-60 ">monthly</span> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="h-[23px] w-[1px] bg-gray-300" /> */}
                        <div className="flex items-center py-2 w-[28%]">
                          <div className="flex flex-row gap-4">
                            <button
                              type="btn"
                              className="flex felx-row rounded-xl py-2 text-[12px] font-normal px-4 border border-2 outline-[#7F8FA4] outline-offset-1"
                            >
                              Cancel Subscription
                            </button>
                            <Link
                              to="/plansprice"
                              type="btn"
                              className="flex felx-row rounded-xl py-2 text-[12px] font-normal px-4 border border-2 outline-[#7F8FA4] outline-offset-1"
                            >
                              Upgrade Plan
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                    //  web view end
                  }
                </div>
              ))}
            </div>

            {/* <div className="flex flex-row  gap-3">
              <div className=" flex items-center gap-2 px-4 py-2">
                <p>dsdsa</p>
              </div>
              <div className="h-[23px] w-[1px] bg-gray-300" />
              <div className=" flex items-center gap-2 px-4 py-2">
                <p>dsdsa</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default Subscriptions;
