import React, { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineSettingsInputComponent } from "react-icons/md";
import image1 from "../../assets/imagenew.png";
import SecHead from "../SectionHeader/SecHead";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import horizontalline from "../../assets/icons/Path 23099.svg";
import { useDispatch, useSelector } from "react-redux";
import { getforyoudata } from "../../redux/actions/publicAction";
import moment from "moment";
import { Link } from "react-router-dom";
const ForYouData = () => {
  const dispatch=useDispatch();
  const {forYou}=useSelector((state)=>state.publicReducer)
 

  useEffect(()=>{
    dispatch(getforyoudata(6))
  },[])

  

  return (
    <>
    <div className=" bg-white md:bg-transparent w-full rounded-xl   ">
      <div className="flex w-full flex-row items-start  justify-between">
        <div className="flex-[2] flex-col w-full md:w-auto  mb-4 md:mb-0 mx-4 md:mx-0 py-4">
          {/* <h3 className="text-[#1A1A1A] cursor-pointer flex items-center gap-2 text-lg md:text-xl lg:text-2xl font-semibold">
              <span>For You</span>
              <IoIosArrowForward />
            </h3> */}
          <SecHead title={"For You"} redirectLink='/for-you'/>
          <p className="recme_shown">Recommended based on your interests</p>
        </div>
        <div className=" py-4 pe-4 md:pe-0">
        <button className="flex-1 me-2 md:me-0 h-10 p-2 rounded-full flex gap-2 items-center justify-center cursor-pointer shadow-sm md:bg-white bg-gray-200 w-full md:w-32 mt-4 md:mt-0">
          {/* <MdOutlineSettingsInputComponent /> */}
          <img src={horizontalline} alt="customize-icon" className="h-[15px] w-[15px]"/>
          <span>Customize</span>
        </button>
        </div>
      </div>
      <div className="bg-white w-full rounded-xl  p-3 md:p-5">
        <div className="max-h-[900px] md:max-h-[900px] ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 ">
            {Array.isArray(forYou) && forYou.map((item, index) => (
             <Link to={`/for-you-details/${item?.uuid}`}>
                <div
                  key={item.id}
                  className={`relative flex flex-1 flex-row justify-between items-start md:items-center gap-4 py-4 md:py-2  ${(index === forYou.length-1 && index === forYou.length -2) ? "border-none":"border-b"} px-0 md:px-0`}
                >
                  <div className="flex flex-col">
                    {/* <span className="text-black text-xs md:text-sm lg:text-base font-semibold">
                    {item.category}
                  </span> */}
                    <CategoryPolls title={item?.category?.name} id={item?.category?.id}/>
                    <span className="title_shown mt-1">{item.title}</span>
                    <span className="date_shown mt-4"> {moment(item[0]?.createdAt).format("MMMM D, YYYY")}</span>
                  </div>
                  <img
                    src={item?.file?.[0]?.url}
                    alt={item.fileId}
                    className={`w-20 md:w-16 h-16 object-cover rounded-xl mt-2 `}
                  />
                  {index % 2 === 0 && (
                    <div className=" hidden md:block absolute right-[-20px] h-full min-h-[125px] w-[1px]  bg-gray-200" />
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ForYouData;
