function ResponseModel({ iconRes, msgRes, textRes, isPage }) {
  return (
    <div
      className={`${
        isPage ? 'relative flex items-center justify-center min-h-screen' : 'fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50'
      }`}
    >
      <div
        className={`rounded-lg ${
          isPage ? 'bg-white p-6' : 'bg-white shadow-lg p-6'
        } mx-4 md:mx-0 md:w-[25rem]`}
      >
        <div className="flex flex-col items-center text-center">
          <img
            src={iconRes}
            alt="icon"
            className="h-20 w-20 mb-4"
          />
          <div className="text-[28px] font-semibold mb-2">{msgRes ?? ""}</div>
          <div className="text-[14px] text-gray-600">{textRes ?? ""}</div>
        </div>
      </div>
    </div>
  );
}
export default ResponseModel;