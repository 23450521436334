import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";

function Settings() {
  const settingData = [
    {
      id: 1,
      title: "Sounds Effect",
    },
    {
      id: 2,
      title: "Autoplay Videos",
    },
    {
      id: 3,
      title: "Notifications",
    },
  ];

  return (
    <>
     <div className="bg_home flex flex-col pb-8 bg-white md:bg-[#F7F8FA]">
        <div className="bg_home px-4 md:px-40 flex flex-col pb-14">
          <div className="bg-white w-full rounded-xl md:shadow-sm p-3 md:p-5 mt-6">
            <PreSectionHeader title={"Settings"} />
            <div className="flex flex-col md:flex-row gap-5 md:gap-10 p-4 ">
              {settingData.map((ele) => (
                <div
                  key={ele?.id}
                  className="bg-gray-100 rounded-[10px] flex items-center gap-20 px-4 justify-between py-4"
                >
                  <div className="text-[14px]  font-medium">
                    {ele?.title}
                  </div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" />
                    <div className="relative w-7 h-4 bg-gray-200 rounded-full peer-focus:outline-none peer-checked:after:translate-x-full peer-checked:bg-[#FF3D00] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all"></div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
