import { ACCOUNT_UPDATE, GET_ACCOUNT, GET_FOLLOW_INTEREST, GET_FOLLOWING_DATA, GET_MODEL_INTEREST_DATA, GET_SEARCH_TAGS_DATA, GET_TAGS_DATA, GET_TOP_STORIES, GET_TRANDING, POST_INTEREST, SELECTED_INTERESTED_DATA, SELECTED_TAGS_ISBOREDED } from "../actions/type";

const initialState = {
    topStoriesData:[],
    followyourinterest:[],
    followingData:[],
    selectedInterest:"",
    getTags:[],
    searchTags:"",
    modelinterestData:[],
    getaccount:{},
    tranding:[],
    isBorededSelectedTags:[],
    confirmUpdateAccount:"",
    postInterestRes:""
}

const privateApiReducer = (state = initialState, action) => {
    // console.log("action",action?.payload?.data);
    
    switch (action.type) {
        case GET_TOP_STORIES:return{...state,topStoriesData:action?.payload?.data}
        case GET_TRANDING:return{...state,tranding:action?.payload?.data}
        case GET_FOLLOW_INTEREST:return { ...state, followyourinterest: action.payload?.data }
        case POST_INTEREST:return { ...state, postInterestRes: action.payload?.msg }
        case GET_FOLLOWING_DATA:return{...state,followingData:action.payload?.data}
        case SELECTED_INTERESTED_DATA:return{...state,selectedInterest:action?.payload?.data}
        case GET_TAGS_DATA:return{...state,getTags:action?.payload?.data}
        case GET_SEARCH_TAGS_DATA:return{...state,searchTags:action?.payload?.data}
        case GET_MODEL_INTEREST_DATA:return{...state,modelinterestData:action.payload?.data}
        case ACCOUNT_UPDATE:return{...state,confirmUpdateAccount:action?.payload?.msg}
        case GET_ACCOUNT:return{...state,getaccount:action?.payload?.data}
        case SELECTED_TAGS_ISBOREDED:return{...state,isBorededSelectedTags:action?.payload?.data}
        default:return state;
    }
}
export default privateApiReducer;