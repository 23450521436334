// actions/authActions.js

import api from "../api";
import { GET_LOGIN, OTP_VERIFIED, RESEND_OTP_VERIFIED } from "./type";

// Thunk Action
export const loginUser = (email) => async (dispatch) => {
    // console.log("response", email);

    try {
        const response = await api.post("/user/auth/sign-in", { email });
        if (response?.status === 200) {
            // console.log("SHOW_TOSTER", SHOW_TOSTER, response);

            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });

            dispatch({ type: GET_LOGIN, payload: response?.data });
        } else {
            // console.log("loginUser error", response);
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "error", message: response?.data?.msg },
            // });
        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
};

export const verifyOtp = (payload) => async (dispatch) => {
    // console.log("otp action", payload);

    try {
        const response = await api.post("/user/auth/verify-otp", payload);
        // console.log("responseotp", response);
        if (response?.status === 200) {
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        dispatch({ type: OTP_VERIFIED, payload: response?.data });
        }else{

        }
    } catch (error) {
        // console.log("responseotperror", error);
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: {
        //         type: "error",
        //         message: error?.response?.data?.msg ,
        //     },
        // });
        // dispatch({ type: OTP_VERIFICATION_FAILED, payload: error });
    }
};

export const resendOTP =(payload)=>async(dispatch)=>{
    try {
        const response = await api.post("/user/auth/resend-otp", payload);
        // console.log("responseotp", response);
        if (response?.status === 200) {
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        dispatch({ type: RESEND_OTP_VERIFIED, payload: response?.data });
        }else{

        }
    } catch (error) {
        // console.log("responseotperror", error);
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: {
        //         type: "error",
        //         message: error?.response?.data?.msg ,
        //     },
        // });
        // dispatch({ type: OTP_VERIFICATION_FAILED, payload: error });
    }
}

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: "LOGOUT_USER" });
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: "logout  success" },

        // });
    };
};


