import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import followingIcons1 from "../../assets/icons/Group26312.svg";
import followingIcons2 from "../../assets/icons/Group26314.svg";
import followingIcons3 from "../../assets/icons/Group26316.svg";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  getFollowingData,
  getHashTags,
  getSelectedInterest,
  sendSelectedTags,
} from "../../redux/actions/privateApiAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import backarrow from "../../assets/icons/Path 23107.svg";
function YourTopicModel({ isOpen, onClose }) {
  const dispatch = useDispatch();
  // const [clickedId, setClickedId] = useState(null);
  const [clickedSubTopicsId, setClickedSubTopicsId] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [showTagsData, setShowTagsData] = useState([]);
  const [selectedSubTagsByTopic, setSelectedSubTagsByTopic] = useState({});
  const { followingData, getTags, selectedInterest } = useSelector(
    (state) => state.privateReducer
  );

  useEffect(() => {
    dispatch(getFollowingData());
  }, [dispatch]);

  // const handleClick = (id, type) => {
  //   if (type === "topics") {
  //     setClickedId(id === clickedId ? null : id);
  //     setClickedSubTopicsId(null);
  //     setIsButtonActive(false);
  //   } else if (type === "subtopics") {
  //     setClickedSubTopicsId(id === clickedSubTopicsId ? null : id);
  //     setIsButtonActive(true);
  //   }
  // };
  console.log("selectedInterest?.topic?.id", selectedInterest?.topic?.id);

  const handleClick = (id, type) => {
    if (type === "topics") {
      setClickedSubTopicsId(id === clickedSubTopicsId ? null : id);
      setIsButtonActive(false);
      setSelectedSubTagsByTopic((prevState) => ({ ...prevState, [id]: [] }));
      dispatch(getSelectedInterest(id));
    } else if (type === "subtopics") {
      const currentSelectedSubTags =
        selectedSubTagsByTopic[selectedInterest?.topic?.id] || [];
      const newSelectedSubTags = currentSelectedSubTags.includes(id)
        ? currentSelectedSubTags.filter((subTagId) => subTagId !== id)
        : [...currentSelectedSubTags, id];

      setSelectedSubTagsByTopic((prevState) => ({
        ...prevState,
        [selectedInterest?.topic?.id]: newSelectedSubTags,
      }));
      setIsButtonActive(newSelectedSubTags.length > 0);
    }
  };
  useEffect(() => {
    setShowTagsData(getTags);
  }, [getTags]);
  useEffect(() => {
    if (selectedInterest?.topic?.id) {
      dispatch(getHashTags(selectedInterest?.topic?.id));
    }
  }, [selectedInterest?.topic?.id, dispatch]);

  // setClickedSubTopicsId

  // const selectedTopic = followingData.find((item) => item.id === clickedId);
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Prepare payloads
    const payloads = Object.keys(selectedSubTagsByTopic)
      .map((topicId) => {
        const tags = selectedSubTagsByTopic[topicId];
        if (tags.length > 0) {
          return { topicId, tags };
        }
        return null;
      })
      .filter((payload) => payload !== null);

    // Check if there are valid payloads to send
    if (payloads.length > 0) {
      dispatch(sendSelectedTags({ multipleTags: payloads }));
      // .then(() => {
      //   // Optionally, you can handle success here (e.g., close modal, show message)
      //   onClose();
      // })
      // .catch((error) => {
      //   // Handle error here (e.g., show error message)
      //   console.error('Error sending selected tags:', error);
      // });
    } else {
      console.log("No tags selected to send.");
    }
  };

  console.log("match", selectedSubTagsByTopic[selectedInterest?.topic?.id]);

  if (!isOpen) return null;
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 rounded md:[50rem]">
          <div className="text-center mb-4">
            <div className="text-center">
              <div className="flex flex-row items-center justify-between">
                <div className="font-semibold text-[12px] mb-2">
                  {/* <PreSectionHeader title={"Customise your topics"} /> */}
                  <h3
                    className={`cursor-pointer flex items-center gap-2  font-sans  text-[18px] font-medium  tracking-normal text-[#1A1A1A]`}
                  >
                    {/* <IoMdArrowRoundBack /> */}
                    <img
                      src={backarrow}
                      alt="back"
                      className={"w-3 h-3"}
                      onClick={onClose}
                    />
                    <span className="text-[14px]">Follow your interests</span>
                  </h3>
                </div>
                {/* <button
                  className="h-10 p-2 rounded-full flex gap-2 items-center justify-center cursor-pointer border bg-gray-200 w-full md:w-32 md:bg-white"
                  onClick={openModal}
                >
                  <MdOutlineSettingsInputComponent />
                  <span>Customize</span>
                </button> */}
              </div>
            </div>

            <div className="flex flex-col gap-5 py-2 mb-2 overflow-y-auto max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-100px)]">
              <div className="flex flex-row overflow-x-auto gap-5 scrollbar-hide">
                {Array.isArray(followingData) &&
                  followingData.map(
                    (item) => (
                      console.log("itemyout", item, clickedSubTopicsId),
                      (
                        <div
                          key={item.id}
                          className={`rounded-xl shadow-sm w-24 cursor-pointer ${
                            clickedSubTopicsId === item.uuid
                              ? "border border-[#FF3D00] bg-transparent"
                              : "bg-[#F7F8FA]"
                          }`}
                          onClick={() => handleClick(item.uuid, "topics")}
                        >
                          <div className="text-center flex flex-col items-center p-4 py-5">
                            <img
                              src={item?.topic?.icon?.[0]?.url}
                              alt="Icon"
                              className="w-auto h-10"
                            />
                            <div
                              className="pt-2 text-center max-w-full text-[12px] opacity-90 font-semibold leading-none text-gray-700"
                              style={{ overflowWrap: "break-word" }}
                            >
                              {item?.topic?.name}
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
              </div>
            </div>

            <div className="text-[12px] text-[#7F8FA4] mt-6 justify-start   text-start">
              Select leaders that'll appear in your For You news feed and under
              what you're Following
            </div>

            {/* {selectedTopic && selectedTopic?.child && ( */}
            <div className="mt-2">
              <div className="text-[12px] font-semibold  justify-start   text-start">
                You Liked
              </div>
              <div className="py-3">
                <div className="flex flex-row gap-5 flex-wrap">
                  {Array.isArray(showTagsData) &&
                    showTagsData.map(
                      (ele) => (
                        console.log("ele?.id", ele?.uuid),
                        (
                          <div
                            key={ele?.id}
                            className={`border rounded-full px-5 py-3 font-bold cursor-pointer ${
                              selectedSubTagsByTopic[
                                selectedInterest?.topic?.id
                              ]?.includes(ele?.id)
                                ? "bg-[#E2E7EE] "
                                : "bg-transparent"
                            }`}
                            onClick={() => handleClick(ele?.id, "subtopics")}
                          >
                            <div className="text-[10px] text-gray-600">
                              #23{ele?.name}
                            </div>
                          </div>
                        )
                      )
                    )}
                </div>
              </div>
            </div>
            {/* )} */}

            {/* <div className="pt-10">
              <div className="flex flex-col items-center">
                
              </div>
            </div> */}
          </div>
          <div className="flex justify-center gap-2">
            <button
              onClick={onClose}
              className="rounded-lg py-2 text-[14px] font-semibold px-24 outline outline-1 outline-offset-2"
            >
              Cancel
            </button>
            <button
              // to={isButtonActive ? "/Following" : "#"}
              className={`rounded-lg px-10 py-2 text-[14px] font-semibold px-24 bg-[#FF3D00] text-white ${
                isButtonActive ? "" : "opacity-30 cursor-not-allowed"
              }`}
              aria-disabled={!isButtonActive}
              onClick={(e) => {
                if (isButtonActive) {
                  handleSubmit(e);
                } else {
                  e.preventDefault();
                }
              }}
              // onClick={onClose}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default YourTopicModel;
