import React from "react";
// import { IoMdArrowRoundBack } from "react-icons/io";
import backarrow from "../../assets/icons/Path 23107.svg";

const PreSectionHeader = ({ title,textbold }) => {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <h3 className={`cursor-pointer flex items-center gap-2  font-sans  ${textbold ? "text-[18px] font-medium":"text-xs font-semibold"}  tracking-normal text-[#1A1A1A]`}>
      {/* <IoMdArrowRoundBack /> */}
      <img
        src={backarrow}
        alt="back"
        className={` ${textbold?"w-4 h-4 font-bold":"w-3 h-3"}`}
        onClick={handleBack}
      />
      <span>{title}</span>
    </h3>
  );
};

export default PreSectionHeader;
