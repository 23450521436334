import { Link } from "react-router-dom";
import WithLayout from "../../components/Layout/Layout";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import image1 from "../../assets/image1modipm.png";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import ResponseModel from "../../components/CustomModel/ResponseModel";
import nonotification from "../../assets/response-icon/nonotification.svg";

const Notification = () => {
  const notificationData = [
    // {
    //   id: 1,
    //   image: image1,
    //   title:
    //     "India vs China in Ladakh: Chinese withdrawal only solution. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   date: "June 11, 2024",
    // },
    // {
    //   id: 2,
    //   image: image1,
    //   title:
    //     "India vs China in Ladakh: Chinese withdrawal only solution. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   date: "June 11, 2024",
    // },
    // {
   
  ];

  return (
    <>
     <div className="bg_home flex flex-col pb-8 bg-white md:bg-[#F7F8FA]">
        <div className="bg_home px-4 md:px-40 flex flex-col pb-14">
          <div className="">
          <div className="bg-white w-full rounded-xl md:shadow-sm p-3 md:p-5 mt-6">
              <PreSectionHeader title={"Notification"} />
              <div className="max-h-auto my-4 md:my-0">
              {Array.isArray(notificationData) && notificationData.length > 0 ? (
                notificationData.map((ele,index) => (
                  <>
                  <Link
                    to="#"
                    key={ele.id}
                    className={`flex flex-row items-start gap-5  relative ${index === notificationData.length-1 ? "border-none":"border-b"} py-4`}
                  >
                    <img
                      src={ele.image}
                      alt={ele.title}
                      className="w-16 h-16 md:w-16 md:h-16 object-cover rounded-md flex-shrink-0"
                    />
                    <div className="flex flex-col gap-1">
                      <span className="text-[#1A1A1A] font-normal text-sm md:text-[14px] opacity-90 mb-3  overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-pre-wrap md:max-w-full max-w-[260px] sm:max-w-[300px]">
                        {ele.title}
                      </span>

                      <span className="mt-4 date_shown bottom-5 absolute">
                        {ele.date}
                      </span>
                    </div>
                  </Link>
                  

                  {/* <hr className="border-b-1  my-3 font-semibold " /> */}
                  </>
                ))):(<ResponseModel
                  iconRes={nonotification}
                  msgRes="No notifications found!"
                  // textRes="Nothing to show..."
                  isPage={true}
                />
             )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Notification.Layout = WithLayout;
export default Notification;
