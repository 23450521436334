import WithLayout from "../../components/Layout/Layout";
import PridictionComp from "../../components/PridictionComp/PridictionComp";

function Pridiction() {
    return(
  
      <PridictionComp />
    )
  }
  Pridiction.Layout = WithLayout;
  export default Pridiction;