import { Link, useLocation, useParams } from "react-router-dom";
import WithLayout from "../../components/Layout/Layout";
import LocalNews from "../../components/HomeComp/LocalNewsHomeComp";
import TopStoriesDetailsCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsCom";
import { useEffect, useState } from "react";
import TopStoriesDetailsMobileCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsMobileCom";
import { useDispatch, useSelector } from "react-redux";
import { getPublicLatestPost } from "../../redux/actions/publicAction";
import moment from "moment";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import SecHead from "../../components/SectionHeader/SecHead";
import ForYouData from "../../components/HomeComp/ForYouData";
import LatestNewsBottom from "../../components/commonComponenets/LatestNewsBottom";

function YourTopicDetailsId() {
  const { id, slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const { latestPost } = useSelector((state) => state.publicReducer);
  useEffect(() => {
    dispatch(getPublicLatestPost(3));
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isVerified = localStorage.getItem("accessToken");
  // bradcumb
  const pathSegments = location.pathname.split("/").filter(Boolean);
  // pathSegments.pop();
  const breadCumb = [
    { id: 1, name: isVerified ? "For You" : "News", link: "/" },
    ...pathSegments.map((segment, index) => {
      const link = `/${pathSegments.slice(0, index + 1).join("/")}`;
      // console.log("link", link);

      return { id: index + 2, name: formatBreadcrumbName(segment), link };
    }),
  ];
  function formatBreadcrumbName(name) {
    return name
      .replace(/-/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
  return (
    <>
      <div className="bg_home  bg-[#F7F8FA] md:px-40 flex flex-col pb-14">
        <div className="flex-[2] ">
          {isMobile ? (
            <div className="flex flex-col  gap-5 ">
              <div className=" flex flex-col ">
                <TopStoriesDetailsMobileCom postDetailsid={slug} />
              </div>
            </div>
          ) : (
            <>
              <div className="py-4">
                {breadCumb.map((item, index) => (
                  <span
                    key={item.id}
                    className={`text-[10px] font-medium ${
                      index === breadCumb.length - 1 ? "" : "opacity-60"
                    }`}
                  >
                    {index > 0 && " > "}
                    <Link to={item.link} className="breadcrumb-link">
                      {item.name}
                    </Link>
                  </span>
                ))}
              </div>
              <div className="flex flex-row gap-5 mb-5">
                <div className="flex-[2] flex flex-col gap-5">
                  <TopStoriesDetailsCom postDetailsid={slug} />
                </div>

                <div className="flex-1 flex flex-col gap-5">
                  <ForYouData />
                </div>
              </div>
            </>
          )}
  {/* latest post common componenet */}
  <LatestNewsBottom/>
        </div>
      </div>
    </>
  );
}
YourTopicDetailsId.Layout = WithLayout;
export default YourTopicDetailsId;
