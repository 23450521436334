import WithLayout from "../../components/Layout/Layout";
import Payment from "../../components/Payment/Payment";
import Subscription from "../../components/Subscription/Subscription";

function Plansprice() {
  return (
    <>
      <div className="bg_home bg-[#F7F8FA]  px-4 md:px-40 flex flex-col md:pt-8 pb-14">
        <div className="flex flex-col w-full md:flex-row gap-5 md:py-[10px]">
          <div className="w-full md:w-[60%]">
            <Subscription />
          </div>
          <div className="w-full md:w-[40%] h-auto ">
            <Payment />
          </div>
        </div>
      </div>
    </>
  );
}
Plansprice.Layout = WithLayout;
export default Plansprice;
