import { Link } from "react-router-dom";
import PreSectionHeader from "../PreSectionHeader/PreSectionHeader";
import backarrow from "../../assets/icons/Path 23107.svg";

const YourInteresteModel = ({
  isOpen,
  onClose,
  title,
  submitText,
  showInterestData,
  isButtonActiveInterestModel,
  handleClickInterest,
  selectedIds,
  handleSubmitInterestModel,
}) => {
  return (
    <>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${
          !isOpen && "hidden"
        }`}
      >
        <div className="bg-white p-4 rounded md:w-11/12 lg:w-3/4 xl:w-1/2 max-w-full h-full md:h-auto md:max-h-full overflow-auto relative">
          <div className="text-center mb-4">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-[12px]">
                {/* <PreSectionHeader title={title ?? "Follow your interests"} /> */}
                <h3
                  className={`cursor-pointer flex items-center gap-2  font-sans  text-[18px] font-medium  tracking-normal text-[#1A1A1A]`}
                >
                  {/* <IoMdArrowRoundBack /> */}
                  <img
                    src={backarrow}
                    alt="back"
                    className={"w-3 h-3"}
                    onClick={onClose}
                  />
                  <span className="text-[14px]">Follow your interests</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="text-[12px] text-[#7F8FA4] my-2">
            Choose topics that'll appear in your For You news feed and under
            what you're Following
          </div>
          <div
            className="flex  gap-4 py-2 mb-6 flex-wrap "
            // style={{width:"max-content"}}
          >
            {Array.isArray(showInterestData) &&
              showInterestData.map((item) => (
                <div
                  className={`rounded-xl shadow-sm cursor-pointer p-2  ${
                    selectedIds.includes(item.id)
                      ? "border-2  bg-gray-200"
                      : "border-2 border-[#E2E7EE]"
                  } `}
                  style={{ width: "fit-content" }}
                  key={item.id}
                  onClick={() => handleClickInterest(item)}
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={item?.icon?.[0]?.url}
                      alt="Icon"
                      className="w-6 h-auto"
                    />
                    <div className="text-[12px] opacity-90 font-semibold text-gray-700">
                      {item?.name}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="md:pt-10 ">
            <div className="flex flex-col items-center absolute inset-x-0  md:relative bottom-4 text-center">
              <div className="text-sm text-center mb-2">
                {selectedIds.length} topics selected (min. 3 topics)
              </div>
              <div className="flex flex-row gap-2">
                <Link
                  to="#"
                  className="rounded-lg py-2 text-[14px] font-semibold  px-24 border text-black hidden md:block"
                  onClick={onClose}
                >
                  Cancel
                </Link>
                <button
                  onClick={() => {
                    if (isButtonActiveInterestModel)
                      handleSubmitInterestModel();
                  }}
                  className={`rounded-lg py-2 text-[14px] font-semibold px-24 bg-[#FF3D00] text-white ${
                    isButtonActiveInterestModel
                      ? ""
                      : "opacity-30 cursor-not-allowed"
                  }`}
                  aria-disabled={!isButtonActiveInterestModel}
                >
                  {submitText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourInteresteModel;
