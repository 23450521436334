import LoginLayout from "../../components/LoginLayout/LoginLayout";
import successfullyPayment from "../../assets/icons/Group 25325.png";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function PaymentSuccess() {
  const navigate = useNavigate();
  const [getLoggedIn, setGetLoggedIn] = useState();

  const handleButtonClick = () => {
    if (getLoggedIn) {
      navigate("/subscriptions");
    }
  };

  useEffect(() => {
    const isVerified = localStorage.getItem("isVerified") === "true";
    setGetLoggedIn(isVerified);
  }, []);

  return (
    <>
      <div
        className="flex flex-col items-center justify-center h-screen p-4"
        onClick={handleButtonClick}
      >
        <div className="w-full max-w-sm p-4 ">
          <div className="text-center mb-4">
            <img
              src={successfullyPayment}
              alt="Payment Successful"
              className="mx-auto"
            />
          </div>
          <div className="text-center text-2xl  text-[#1A1A1A] mb-2">
            Your Payment was successful!
          </div>
          <div className="text-center text-gray-600 text-xs">
            <div>Order ID: #3040BH4Y6Y</div>
            <div>Your transaction ID: asdcer12345tg</div>
          </div>
        </div>
      </div>
    </>
  );
}

PaymentSuccess.Layout = LoginLayout;
export default PaymentSuccess;
