import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import downloadicon from "../../assets/icons/Group 26575.svg";
import { useEffect, useState } from "react";

function Purchasehistory() {
  const purchaseHistoryData = [
    {
      id: 1,
      order_id: "#120000",
      date_time: "Jun 12, 2024, 12:03pm",
      total_price: "$8",
      payment_type: "UPI",
    },
    {
      id: 2,
      order_id: "#120000",
      date_time: "Jun 12, 2024, 12:03pm",
      total_price: "$8",
      payment_type: "UPI",
    },
  ];
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className="bg_home flex flex-col pb-8 bg-white md:bg-[#F7F8FA]">
        <div className="bg_home px-4 md:px-40 flex flex-col pb-14">
          <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 mt-6">
            <PreSectionHeader title={"Purchase History"} />
            <div className="items-start gap-5 my-4 p-4 border-2 rounded-lg">
              <div className="flex flex-row gap-2 opacity-90">
                <div className="uppercase  text-sm font-medium ">
                  POLITICAL PULSE BEST VALUE -
                </div>
                <div className="">1 YEAR PLAN</div>
              </div>

              {purchaseHistoryData.map((purchsehis) => (
                <div className="overflow-y-auto scrollbar-hide">
                  {isMobile ? (
                    // mobile responsive
                    <div className="grid gap-4 grid-cols-2  my-3">
                      {/* Order ID */}
                      <div
                        key={purchsehis?.id}
                        className="flex  gap-2  col-span-1"
                      >
                        <div className="flex flex-col">
                          <div className="flex flex-row gap-1">
                            <div className="opacity-90 text-[17px]">
                              Order ID
                            </div>
                          </div>
                          <div className="text-[17px] font-semibold opacity-90">
                            {purchsehis?.order_id}
                          </div>
                        </div>
                      </div>

                      {/* Date & Time */}
                      <div className="flex  gap-2  col-span-1">
                        <div className="flex flex-col">
                          <div className="flex flex-row gap-1">
                            <div className="opacity-90 text-[17px]">
                              Date & Time
                            </div>
                          </div>
                          <div className="text-[17px] font-semibold opacity-90">
                            <span className="">{purchsehis?.date_time}</span>
                          </div>
                        </div>
                      </div>

                      {/* Total Price */}
                      <div className="flex  gap-2  col-span-1">
                        <div className="flex flex-col">
                          <div className="flex flex-row gap-1">
                            <div className="opacity-90 text-[17px]">
                              Total Price
                            </div>
                          </div>
                          <div className="text-[17px] font-semibold opacity-90">
                            <span className="">{purchsehis?.total_price}</span>
                          </div>
                        </div>
                      </div>

                      {/* Payment Type */}
                      <div className="flex  gap-2  col-span-1">
                        <div className="flex flex-col">
                          <div className="flex flex-row gap-1">
                            <div className="opacity-90 text-[17px]">
                              Payment Type
                            </div>
                          </div>
                          <div className="text-[17px] font-semibold opacity-90">
                            <span className="">{purchsehis?.payment_type}</span>
                          </div>
                        </div>
                      </div>

                      {/* Download Invoice Button */}
                      <div className="flex items-center justify-center col-span-2">
                        <button
                          type="button"
                          className="flex items-center justify-center rounded-xl py-2 text-[12px] font-normal px-4 border-2 border-gray-400 outline-none w-full"
                        >
                          <img
                            src={downloadicon}
                            alt="download"
                            className="h-3 w-3 mr-2"
                          />
                          <span>Download Invoice</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    // web screen
                    <div className="flex flex-row gap-3 py-2 w-full">
                      <div
                        key={purchsehis?.id}
                        className="flex  items-center gap-2 py-2 px-5 md:w-[20%] w-full"
                      >
                        <div className="justify-start md:flex md:flex-col">
                          <div className=" flex flex-row gap-1">
                            <div className="opacity-90 text-[10px]">
                              Order ID
                            </div>
                          </div>
                          <div className="text-[10px] font-semibold ">
                            {purchsehis?.order_id}
                          </div>
                        </div>
                      </div>
                      <div className="h-auto w-[1px] bg-gray-300 my-4 mt-3" />
                      <div className="flex items-center gap-2 md:px-6  md:w-[20%] w-full">
                        <div className=" justify-start md:flex md:flex-col">
                          <div className=" flex flex-row gap-1 ">
                            <div className="opacity-90 text-[10px]">
                              Date & Time
                            </div>
                          </div>
                          <div className="text-[10px] font-semibold ">
                            <span className=" text-xs">
                              {purchsehis?.date_time}
                            </span>{" "}
                            {/* <span className="opacity-60 ">monthly</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="h-auto w-[1px] bg-gray-300 my-4 mt-3" />
                      <div className="flex items-center gap-2 md:px-6 px-5 md:w-[20%] w-full">
                        <div className=" justify-start md:flex md:flex-col">
                          <div className=" flex flex-row gap-1">
                            <div className="opacity-90 text-[10px]">
                              Total Price
                            </div>
                          </div>
                          <div className="text-[10px] font-semibold ">
                            <span className=" text-xs">
                              {purchsehis?.total_price}
                            </span>{" "}
                            {/* <span className="opacity-60 ">monthly</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="h-auto w-[1px] bg-gray-300 my-4 mt-3" />
                      <div className="flex items-center gap-2 md:px-6  px-5 md:w-[20%] w-full">
                        <div className=" justify-start md:flex md:flex-col">
                          <div className=" flex flex-row gap-1">
                            <div className="opacity-90 text-[10px]">
                              Payment Type
                            </div>
                          </div>
                          <div className="text-[10px] font-semibold ">
                            <span className=" text-xs">
                              {purchsehis?.payment_type}
                            </span>{" "}
                            {/* <span className="opacity-60 ">monthly</span> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="h-[23px] w-[1px] bg-gray-300" /> */}
                      <div className="flex items-center py-2 px-5 md:w-[20%] w-full">
                        <div className="">
                          <button
                            type="button"
                            className="flex flex-row items-center justify-center rounded-xl py-2 text-[12px] font-normal px-4 border-2 border-gray-400 outline-none"
                          >
                            <img
                              src={downloadicon}
                              alt="download"
                              className="h-3 w-3 mr-2"
                            />
                            <span>Download Invoice</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* <div className="flex flex-row  gap-3">
              <div className=" flex items-center gap-2 px-4 py-2">
                <p>dsdsa</p>
              </div>
              <div className="h-[23px] w-[1px] bg-gray-300" />
              <div className=" flex items-center gap-2 px-4 py-2">
                <p>dsdsa</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default Purchasehistory;
