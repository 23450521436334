import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import indiaflag from "../../assets/images/flag-img/Mask Group 380ind.png";
import usflag from "../../assets/images/flag-img/Mask Group 380.png";
import { useState } from "react";
import charticon from "../../assets/icons/Path 23145.svg";
import { useNavigate } from "react-router-dom";

function PridictionComp() {
  const followingData = [
    {
      id: 1,
      Image: indiaflag,
      data: "India Poll Prediction",
    },
    {
      id: 2,
      Image: usflag,
      data: "US Poll Prediction",
    },
    {
      id: 3,
      Image: indiaflag,
      data: "India Poll Prediction",
    },
    { id: 4, Image: indiaflag, data: "India Poll Prediction" },
    { id: 5, Image: usflag, data: "US Poll Prediction" },
    { id: 6, Image: indiaflag, data: "India Poll Prediction" },
  ];

  const navigate = useNavigate();
  const [clickedId, setClickedId] = useState(null);

  const handleClick = (id, type) => {
    if (type === "pollprediction") {
      setClickedId(id === clickedId ? null : id);
      setTimeout(() => {
        navigate(`/use-poll-prediction/${id}`);
      }, [200]);
    }
  };

  return (
    <>
      <div className="bg_home px-4 md:px-40 flex flex-col pb-14 bg-white md:bg-[#F7F8FA]">
        <div className="flex flex-row my-5 mx-8">
          <div className="bg-white w-full rounded-xl shadow-sm p-5">
            <div className="text-center">
              <div className="flex flex-row items-center justify-between gap-10 md:gap-0">
                <div className="font-semibold text-[12px] mb-2">
                  <PreSectionHeader title={"Prediction"} />
                </div>
                <button className="h-10 p-2 rounded-full flex gap-2 items-center justify-center cursor-pointer border bg-gray-200 w-full md:w-48 md:bg-white">
                  <img src={charticon} alt="chart-icon" className="w-[16px]" />
                  <span>Prediction History</span>
                </button>
              </div>
            </div>

            <div className="text-[12px] text-[#7F8FA4] my-2">
              Select country to start prediction
            </div>

            <div className="flex flex-col gap-5 py-2 my-2 overflow-y-auto max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-100px)]">
              {/* <div className="flex flex-row overflow-x-auto gap-5 scrollbar-hide">
                {followingData.map((item) => (
                  <div
                    key={item.id}
                    className={`rounded-xl shadow-sm cursor-pointer ${
                      clickedId === item.id
                        ? "border border-[#FF3D00] bg-transparent"
                        : "border border-[#F7F8FA] bg-[#F7F8FA]"
                    }`}
                    onClick={() => handleClick(item.id, "pollprediction")}
                  >
                    <div className="text-center flex flex-col items-center p-4 py-5">
                      <img
                        src={item.Image}
                        alt="Icon"
                        className="w-auto h-auto"
                      />
                      <div className="pt-6 text-center max-w-full text-[12px] opacity-90 font-semibold leading-none text-gray-700">
                        {item.data}
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="flex flex-row overflow-x-auto gap-5 scrollbar-hide">
  {followingData.map((item) => (
    <div
      key={item.id}
      className={`w-36 h-36 flex-shrink-0 rounded-xl shadow-sm cursor-pointer ${
        clickedId === item.id
          ? "border border-[#FF3D00] bg-transparent"
          : "border border-[#F7F8FA] bg-[#F7F8FA]"
      }`}
      onClick={() => handleClick(item.id, "pollprediction")}
    >
      <div className="text-center flex flex-col items-center justify-center h-full ">
        <img
          src={item.Image}
          alt="Icon"
          className="w-auto h-auto max-h-24 max-w-24"
        />
        <div className="pt-4 text-center text-[12px] opacity-90 font-semibold leading-none text-gray-700">
          {item.data}
        </div>
      </div>
    </div>
  ))}
</div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PridictionComp;
