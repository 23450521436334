import React, { useEffect } from "react";
import SecHead from "../SectionHeader/SecHead";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import { useDispatch, useSelector } from "react-redux";
import { trandingData } from "../../redux/actions/privateApiAction";
import moment from "moment";
import { Link } from "react-router-dom";
const TrendingHomeComp = () => {
  const dispatch = useDispatch();
  const { tranding } = useSelector((state) => state.privateReducer);
  useEffect(() => {
    // if (!isVerified) {
    //   dispatch(getPublicPost());
    // } else {
    dispatch(trandingData(3));
    // }
  }, [dispatch]);

  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }

  return (
    <div className="bg-white    md:p-5 py-4 px-4 rounded-lg">
      <SecHead title={"Trending"} redirectLink="/trending" />
      <div className="max-h-[750px] w-full rounded-xl py-3">
        <div className="grid grid-cols-2 gap-4">
          {Array.isArray(tranding) &&
            tranding.map((item, index) => (
              // <Link to={`/trending-details/${item?.uuid}`}>
              <Link
                // to={`/trending-details/${item?.uuid}`}
                to={`/trending/${item?.slug}`}
                key={item.id}
                className={`relative rounded-lg overflow-hidden  ${
                  index % 3 === 0
                    ? "col-span-2 h-64 bg-cover bg-center text-white"
                    : "flex flex-col  items-end gap-4"
                }`}
                style={
                  index % 3 === 0
                    ? { backgroundImage: `url(${item?.file?.[0]?.url})` }
                    : {}
                }
              >
                {index % 3 === 0 ? (
                  <Link
                    // to={`/trending-details/${item?.uuid}`}
                    to={`/trending/${item?.slug}`}
                    className="absolute inset-0 bg-black bg-opacity-10 flex flex-col justify-end "
                  >
                    <div className="flex flex-col p-4 gap-2 md:landing-2">
                      <div className="">
                        {index % 3 === 0 ? (
                          <span className="absolute text-sm font-normal text-white ">
                            <CategoryPolls
                              title={item?.category?.name}
                              bgtranparent="true"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <h3
                        className="title_shown text-wrap font-normal leading-5 w-2/3 md:pt-3"
                        style={{ color: "#fff", opacity: 0.9 }}
                      >
                        {item?.title}
                      </h3>
                      <span
                        className="date_shown "
                        style={{ color: "#fff", opacity: 0.8 }}
                      >
                        {moment(item[0]?.createdAt).format("MMMM D, YYYY")}
                      </span>
                    </div>
                  </Link>
                ) : (
                  <div className="flex flex-col">
                    <img
                      src={item?.file?.[0]?.url}
                      alt={item?.fileId}
                      className="w-full h-32 md:h-48 object-cover rounded-md"
                    />
                    <div className="flex flex-col mt-2 md:mt-0  md:gap-2 md:opacity-90 md:pt-2">
                      <CategoryPolls
                        key={item?.category?.id}
                        title={item?.category?.name}
                        id={item?.category?.id}
                      />
                      <span className="font-medium text-[14px] text-[#1A1A1A] leading-[18px]  tracking-normal opacity-95">
                        {item?.title}
                      </span>
                      <span className="date_shown mt-4">
                        {" "}
                        {moment(item[0]?.createdAt).format("MMMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                )}
                {/* </div> */}
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TrendingHomeComp;
