import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const SecHead = ({ title, csschanges,redirectLink }) => {
  // console.log("redirectLink",redirectLink);
  
  return (
    // <h3 className={`text-[#1A1A1A] cursor-pointer flex items-center gap-2 ${csschanges?"text-sm md:text-[16px]":"text-lg md:text-xl lg:text-2xl"}  font-normal`}>
    <Link to={redirectLink}>  <h3 className="flex font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] ">
      <span>{title}</span>
     <IoIosArrowForward />
     
    </h3>
    </Link>
  );
};

export default SecHead;
