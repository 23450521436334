import FollowYourInterestsComp from "../../components/FollowYourInterestsComp/FollowYourInterestsComp";
import WithLayout from "../../components/Layout/Layout";

function FollowYourIntersts() {
  return (
    <>
  
            <FollowYourInterestsComp />
          {/* </div>
        </div>
      </div> */}
    </>
  );
}
FollowYourIntersts.Layout = WithLayout;
export default FollowYourIntersts;
