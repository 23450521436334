import React from 'react';

const LoginLayout = ({ children }) => (
  <div className="login-layout">
   
    <main>{children}</main>
 
  </div>
);

export default LoginLayout;