import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";

function Termcondition() {
  return (
    <>
      <div className="bg_home flex flex-col pb-8 bg-white md:bg-[#F7F8FA]">
        <div className="bg_home px-4 md:px-40 flex flex-col pb-14">
          <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 mt-6">
            <PreSectionHeader title={"Term & Condition"} />
            <div className="flex flex-row gap-5 md:gap-10 p-4 ">
           <div className=" text-[#7F8FA4] text-[14px]">
            <div className="pb-4">

Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
            </div>
            <div className="pb-4">
                It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
            </div>
            <div className="pb-4">
            This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32
            </div>
           </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Termcondition;
