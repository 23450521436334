import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import LatestNewsBottom from '../../../components/commonComponenets/LatestNewsBottom';
import moment from 'moment';
import CategoryPolls from '../../../components/CategoryPolls/CategoryPolls';
import { useDispatch, useSelector } from 'react-redux';
import TopStoriesDetailsMobileCom from '../../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsMobileCom';
import TopStoriesDetailsCom from '../../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsCom';
import SecHead from '../../../components/SectionHeader/SecHead';
import LocalNewsHomeComp from '../../../components/HomeComp/LocalNewsHomeComp';

function PollsNavDetails() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  
  const { slug, titleSlug,uuid } = useParams();
  console.log("slug, id",slug, titleSlug);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log("location.pathname",location.pathname);
  
  // bradcumb
  const pathSegments = location.pathname.split("/").filter(Boolean);
  // pathSegments.pop();

  console.log("pathSegments",pathSegments);
 const breadCumb = [
  ...pathSegments.map((segment, index) => {
    const link = `/${pathSegments[0]}/general-stories`;

    return { id: index + 3, name: formatBreadcrumbName(segment), link };
  }),
];

function formatBreadcrumbName(name) {
  return name
    .replace(/-/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

  return (
    <div className="bg_home  bg-[#F7F8FA] md:px-40 flex flex-col pb-14">
    <div className="flex-[2] ">
      {isMobile ? (
        <div className="flex flex-col  gap-5 ">
          <div className=" flex flex-col ">
            <TopStoriesDetailsMobileCom postDetailsid={titleSlug} />
          </div>
         
        </div>
      ) : (
        <>
          <div className="py-4">
            {breadCumb.map((item, index) => (
              <span
                key={item.id}
                className={`text-[10px] font-medium  ${
                  index === breadCumb.length - 1 ? "" : "opacity-60"
                }`}
              >
              {index > 0 && " > "}
                <Link to={item.link} className="breadcrumb-link">
                  {item.name}
                </Link>
              </span>
            ))}
          </div>
          <div className="flex flex-row gap-5 mb-5">
            {/* <div className="flex flex-col gap-5"> */}
            <div className="flex-[2] flex flex-col gap-5">
              <TopStoriesDetailsCom postDetailsid={titleSlug} />
            </div>
            <div className="flex-1 flex flex-col gap-5">
            <LocalNewsHomeComp/>
              
            </div>
            {/* </div> */}
          </div>
        </>
      )}
     
      {/* latest post common componenet */}
  <LatestNewsBottom/>
    </div>
  </div>
  );
}

export default PollsNavDetails;
