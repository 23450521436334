import credit_card from "../../assets/icons/Group 20829.svg";
import debit_card from "../../assets/icons/Group 26517.svg";
import net_banking from "../../assets/icons/Group 26516.svg";
import upi from "../../assets/icons/Group 26515.svg";
import amarican_exp from "../../assets/icons/Image 218.png";
import master_card from "../../assets/icons/Group 20793.png";
import rupay from "../../assets/icons/Group 20792.png";
import visa from "../../assets/icons/Group 20791.png";
import drop_down_icon from "../../assets/icons/Path 23105.svg";
import sbi from "../../assets/icons/banklogo/sbi_bank_logo.png";
import icici from "../../assets/icons/banklogo/ICICI_bank_logo.png";
import canara from "../../assets/icons/banklogo/canara_bank_logo.png";
import axis from "../../assets/icons/banklogo/Axis_bank_logo.png";
import hdfc from "../../assets/icons/banklogo/hdfc_bank_logo.png";
import lvb from "../../assets/icons/banklogo/LVB_bank_logo.png";
import otherupi from "../../assets/icons/banklogo/otherupimode.png";
import googleupi from "../../assets/icons/banklogo/googleupi.png";
import phonepaymode from "../../assets/icons/banklogo/phonepaymode.png";
import { Link } from "react-router-dom";
import { useState } from "react";
function Payment() {
  const paymentmode = [
    {
      id: 1,
      payModeIcon: credit_card,
      payModealt: credit_card,
      paytitle: "Credit Card",
    },
    {
      id: 2,
      payModeIcon: debit_card,
      payModealt: "debit_card",
      paytitle: "Debit Card",
    },
    {
      id: 3,
      payModeIcon: net_banking,
      payModealt: "net_banking",
      paytitle: "Net Banking",
    },
    {
      id: 4,
      payModeIcon: upi,
      payModealt: "upi",
      paytitle: "UPI",
    },
  ];

  const cardMode = [
    {
      id: 1,
      cardModeIcon: amarican_exp,
      cardModealt: "american_exp",
    },
    {
      id: 2,
      cardModeIcon: master_card,
      cardModealt: "master_card",
    },
    {
      id: 3,
      cardModeIcon: rupay,
      cardModealt: "rupay",
    },
    {
      id: 4,
      cardModeIcon: visa,
      cardModealt: "visa",
    },
  ];

  const bankdata = [
    {
      id: 1,
      banklogo: sbi,
      bankname: "SBI",
    },
    {
      id: 2,
      banklogo: hdfc,
      bankname: "HDFC Bank",
    },
    {
      id: 3,
      banklogo: icici,
      bankname: "ICICI Bank",
    },
    {
      id: 4,
      banklogo: lvb,
      bankname: "LVB",
    },
    {
      id: 5,
      banklogo: axis,
      bankname: "Axis Bank",
    },
    {
      id: 6,
      banklogo: canara,
      bankname: "Canara Bank",
    },
  ];

  const upidata = [
    {
      id: 1,
      upilogo: googleupi,
      upiname: "Gpay",
    },
    {
      id: 2,
      upilogo: phonepaymode,
      upiname: "Phonepe",
    },
    {
      id: 3,
      upilogo: otherupi,
      upiname: "Other UPI ID",
    },
  ];

  const [creditCardPayModeOn, setCreditCardPayModeOn] = useState(true);
  const [debitcardPayModeOn, setdebitCardPayModeOn] = useState(false);
  const [netbankPayModeOn, setNetbankPayModeOn] = useState(false);
  const [upiPayModeOn, setUpiPayModeOn] = useState(false);

  const handleClick = (id, type) => {
    // console.log("Payment mode selected", id, type);
    if (type === "paymentmode") {
      if (id === 1) {
        setCreditCardPayModeOn(true);
        setdebitCardPayModeOn(false);
        setNetbankPayModeOn(false);
        setUpiPayModeOn(false);
      } else if (id === 2) {
        setCreditCardPayModeOn(false);
        setdebitCardPayModeOn(true);
        setNetbankPayModeOn(false);
        setUpiPayModeOn(false);
      } else if (id === 3) {
        setCreditCardPayModeOn(false);
        setdebitCardPayModeOn(false);
        setNetbankPayModeOn(true);
        setUpiPayModeOn(false);
      } else if (id === 4) {
        setCreditCardPayModeOn(false);
        setdebitCardPayModeOn(false);
        setNetbankPayModeOn(false);
        setUpiPayModeOn(true);
      }
    }
  };
  return (
    <>
      <div className="bg-white p-3 rounded-xl shadow-sm md:p-5">
        <div className="text-[#1A1A1A] cursor-pointer flex items-center gap-2 text-lg md:text-xl lg:text-2xl font-normal">
          Make Payment
        </div>

        <div className="w-full rounded-xl py-3">
          <div className="flex flex-col py-2 mb-2 overflow-y-auto max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-100px)]">
            <div className="flex flex-row overflow-x-auto justify-between scrollbar-hide gap-4">
              {paymentmode.map(
                (item, ind) => (
                  // console.log("ind", ind, item?.id),
                  (
                    <div
                      key={item?.id}
                      // className={`rounded-xl shadow-sm w-24 cursor-pointer  gap-2 ${
                      //   cardPayModeOn || netbankPayModeOn || upiPayModeOn
                      //     ? "bg-white border border-[#FF3D00]"
                      //     : "bg-[#F7F8FA] border border-[#F7F8FA]"
                      // }`}
                      className={`rounded-xl shadow-sm w-24 cursor-pointer gap-2 ${
                        (creditCardPayModeOn && item?.id === 1) ||
                        (debitcardPayModeOn && item?.id === 2)
                          ? "bg-white border border-[#FF3D00]"
                          : netbankPayModeOn && item?.id === 3
                          ? "bg-white border border-[#FF3D00]"
                          : upiPayModeOn && item?.id === 4
                          ? "bg-white border border-[#FF3D00]"
                          : "bg-[#F7F8FA] border border-[#F7F8FA]"
                      }`}
                      onClick={() => handleClick(item?.id, "paymentmode")}
                    >
                      <div className="text-center flex flex-col items-center p-4 ">
                        <img
                          src={item?.payModeIcon}
                          alt={item?.payModealt}
                          className="w-10 h-10"
                        />
                        <div className="pt-2 text-center max-w-full text-[10px] opacity-90 font-semibold leading-none text-gray-700">
                          {item?.paytitle}
                        </div>
                      </div>
                    </div>
                  )
                )
              )}
            </div>
          </div>
        </div>
        {/* enter payment details   */}
        {(creditCardPayModeOn || debitcardPayModeOn) && (
          <>
            <div className="mb-4 flex flex-row gap-5">
              <div>We Accept :</div>
              {cardMode.map((ele, ind) => (
                <div key={ele.id}>
                  <img src={ele.cardModeIcon} alt={ele.cardModealt} />
                </div>
              ))}
            </div>

            {/* Input Fields */}
            <div className="space-y-4">
              {/* <div className="relative w-full">
            <input
              type="number"
              className="h-12 appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 placeholder-transparent peer"
              style={{ border: "1px solid #7F8FA4" }}
              placeholder="Enter Card Number"
            />
            <label className="font-bold text-xs absolute left-3 text-gray-500 transition-all duration-200 ease-in-out transform -translate-y-1/2 top-1/2 peer-placeholder-shown:top-1/2 peer-focus:-translate-y-1/2 peer-focus:top-0 bg-white px-1">
              Enter Card Number
            </label>
          </div> */}

              <div className="input-container">
                <input type="number" id="" placeholder=" " />
                <label> Enter Card Number</label>
              </div>

              <div className="flex flex-wrap justify-between">
                <div className="relative ">
                  <select className="h-12 appearance-none border border-gray-400 rounded-lg w-full py-2 pr-10 pl-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 peer">
                    <option value="" disabled selected hidden></option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                  </select>
                  <img
                    src={drop_down_icon}
                    alt="drop-down"
                    className="h-4 w-4 absolute right-3   pointer-events-none top-4"
                  />
                  <label className="absolute left-3 top-1/2 text-gray-500 transition-all duration-200 ease-in-out transform -translate-y-1/2 bg-white px-1 peer-focus:top-0 peer-focus:px-1 peer-focus:-translate-y-1/2 peer-focus:text-xs peer-valid:top-0 peer-valid:-translate-y-1/2 peer-valid:text-xs">
                    Month
                  </label>
                </div>

                <div className="relative ">
                  <select className="h-12 appearance-none border border-gray-400 rounded-lg w-full py-2 pr-10 pl-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 peer">
                    <option value="" disabled selected hidden></option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </select>
                  <img
                    src={drop_down_icon}
                    alt="drop-down"
                    className="h-4 w-4 absolute right-3   pointer-events-none top-4"
                  />
                  <label className="absolute left-3 top-1/2 text-gray-500 transition-all duration-200 ease-in-out transform -translate-y-1/2 bg-white px-1 peer-focus:top-0 peer-focus:px-1 peer-focus:-translate-y-1/2 peer-focus:text-xs peer-valid:top-0 peer-valid:-translate-y-1/2 peer-valid:text-xs">
                    Year
                  </label>
                </div>

                <div className="input-container">
                  <input type="password" id="" placeholder=" " />
                  <label>CVV</label>
                </div>
              </div>

              <div className="input-container">
                <input type="text" id="" placeholder=" " />
                <label> Cardholder's Name</label>
              </div>
            </div>

            <div className="flex items-center gap-2 my-4">
              <input type="checkbox" className="h-5 w-5 border" />
              <div className="text-sm text-black">
                Save card as per new RBI guidelines.
              </div>
            </div>
          </>
        )}

        {netbankPayModeOn && (
          <>
            <div className=" flex flex-row gap-5">
              <div>Popular Banks</div>
            </div>

            <div className="flex flex-wrap gap-3 w-full py-2 my-2">
              {bankdata.map((ele) => (
                <div
                  className="flex items-center border-2 rounded-md p-1 min-w-[5rem] "
                  key={ele?.id}
                >
                  <img
                    src={ele?.banklogo}
                    alt="bank_logo"
                    className="w-3 h-3 mr-2"
                  />
                  <div className="text-xs flex flex-row">{ele?.bankname}</div>
                </div>
              ))}
            </div>

            <div className="relative my-4">
              <select
                className="h-12 border border-gray-400 rounded-xl w-full py-2 pr-10 pl-3 text-gray-700 leading-tight "
                placeholder="Select Bank"
              >
                <option value="" disabled selected></option>
                <option value="SBI">SBI</option>
                <option value="HDFC">HDFC</option>
              </select>
              {/* <img
                    src={drop_down_icon}
                    alt="drop-down"
                    className="h-4 w-4 absolute right-3   pointer-events-none top-4"
                  /> */}
            </div>
          </>
        )}

        {upiPayModeOn && (
          <>
            <div className=" flex flex-row gap-5">
              <div>Popular Banks</div>
            </div>

            <div className="flex flex-wrap gap-3 w-full py-2 my-2">
              {upidata.map((ele) => (
                <div
                  className="flex items-center border-2 rounded-md p-1 min-w-[5rem] "
                  key={ele?.id}
                >
                  <img
                    src={ele?.upilogo}
                    alt="bank_logo"
                    className="w-3 h-3 mr-2"
                  />
                  <div className="text-xs flex flex-row">{ele?.upiname}</div>
                </div>
              ))}
            </div>

            <div className="border-2 rounded-xl p-3">
              <div className="flex flex-row justify-between ">
              <input type="" id="" placeholder=" Enter UPI ID" className="focus:outline-none focus:border-gray-500 "/>
              {/* <label> Enter UPI ID</label> */}
              <div className="">
             
              </div>
              </div>
            </div>

          </>
        )}

        <div className="text-[10px] mt-12 mb-4">
          I agree with the Privacy Policy by proceeding with this payment.
        </div>
        <div className="flex flex-row">
          <Link
            to="/PaymentSuccess"
            className="w-full rounded-lg py-2 text-[14px] font-semibold bg-[#FF3D00] text-white text-center justify-center"
          >
            Pay Now
          </Link>
        </div>
      </div>
    </>
  );
}
export default Payment;
