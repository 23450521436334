import { SHOW_TOSTER } from "../actions/type";
const initialState = {
  showToaster: ""
}

const toasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOSTER:
      // console.log("showToaster", action.payload);
      return { ...state, showToaster: action.payload }
    default:
      return state;
  }
}
export default toasterReducer;