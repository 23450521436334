import React, { useEffect, useState } from "react";
import { MdOutlineSettingsInputComponent } from "react-icons/md";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import followingIcons1 from "../../assets/icons/Group26312.svg";
import followingIcons2 from "../../assets/icons/Group26314.svg";
import followingIcons3 from "../../assets/icons/Group26316.svg";
import horizontalline from "../../assets/icons/Path 23099.svg";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFollowingData,
  getFollowYourInterest,
  getHashTags,
  getSelectedInterest,
  modelshowInterest,
  modelshowTopic,
  postInterest,
  searchTagData,
  selectedTags,
  sendSelectedTags,
} from "../../redux/actions/privateApiAction";
import YourInteresteModel from "../CustomModel/YourInteresteModel";
import YourTopicModel from "../CustomModel/YourTopicModel";

function FollowingComp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickedId, setClickedId] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [selectedSubTagsByTopic, setSelectedSubTagsByTopic] = useState({});
  const [searchData, setSearchData] = useState("");
  const [showTagsData, setShowTagsData] = useState([]);
  const [checkToken, setCheckToken] = useState(false);

  const [openInterstModel, setOpenInterstModel] = useState(false);
  const [showInterestData, setShowInterestData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showTopicModel, setShowTopicModel] = useState(false);

  const { getbacktodetails } = useSelector((state) => state.publicReducer);
  const {
    followingData,
    selectedInterest,
    getTags,
    searchTags,
    modelinterestData,
    isBorededSelectedTags,
  } = useSelector((state) => state.privateReducer);

  console.log("getbacktodetails", getbacktodetails);

  useEffect(() => {
    setCheckToken(localStorage.getItem("isOnBoard") === "true");
  }, []);

  useEffect(() => {
    dispatch(getFollowingData());
  }, [dispatch]);

  useEffect(() => {
    if (selectedInterest?.topic?.id) {
      if (checkToken) {
        dispatch(selectedTags(selectedInterest.topic.id));
      } else {
        dispatch(getHashTags(selectedInterest.topic.id));
      }
    }
  }, [selectedInterest?.topic?.id, checkToken, dispatch]);

  useEffect(() => {
    if (searchData) {
      dispatch(searchTagData(selectedInterest?.topic?.id, searchData));
    }
  }, [searchData, selectedInterest?.topic?.id, dispatch]);

  useEffect(() => {
    if (searchData) {
      setShowTagsData(searchTags || []);
    } else {
      setShowTagsData(checkToken ? isBorededSelectedTags || [] : getTags || []);
    }
  }, [searchTags, searchData, isBorededSelectedTags, getTags, checkToken]);

  useEffect(() => {
    if (followingData.length > 0) {
      const firstTopicId = followingData[0].uuid;
      setClickedId(firstTopicId);
      dispatch(getSelectedInterest(firstTopicId));
    }
  }, [followingData, dispatch]);

  useEffect(() => {
    setShowInterestData(modelinterestData || []);
  }, [modelinterestData]);

  useEffect(() => {
    const ids = (showInterestData || [])
      .filter((item) => item.isSelected)
      .map((item) => item.id);
    setSelectedIds(ids);
  }, [showInterestData]);

  const handleClick = (id, type) => {
    if (type === "topics") {
      setClickedId(id === clickedId ? null : id);
      setIsButtonActive(false);
      setSelectedSubTagsByTopic((prevState) => ({ ...prevState, [id]: [] }));
      dispatch(getSelectedInterest(id));
    } else if (type === "subtopics") {
      const currentSelectedSubTags =
        selectedSubTagsByTopic[selectedInterest?.topic?.id] || [];
      const newSelectedSubTags = currentSelectedSubTags.includes(id)
        ? currentSelectedSubTags.filter((subTagId) => subTagId !== id)
        : [...currentSelectedSubTags, id];

      setSelectedSubTagsByTopic((prevState) => ({
        ...prevState,
        [selectedInterest?.topic?.id]: newSelectedSubTags,
      }));
      setIsButtonActive(newSelectedSubTags.length > 0);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payloads = Object.keys(selectedSubTagsByTopic)
      .map((topicId) => {
        const tags = selectedSubTagsByTopic[topicId];
        if (tags.length > 0) {
          return { topicId, tags };
        }
        return null;
      })
      .filter((payload) => payload !== null);

    if (payloads.length > 0) {
      dispatch(sendSelectedTags({ multipleTags: payloads }));
      const shouldGoBack = localStorage.getItem("setBack");
      if (shouldGoBack === "true") {
        navigate(getbacktodetails);
        localStorage.removeItem("setBack");
      } else {
        navigate("/");
      }
    }
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  const handleInterstModel = () => {
    setOpenInterstModel(true);
    dispatch(modelshowInterest());
  };

  const handleCloseFirst = () => {
    setOpenInterstModel(false);
    setShowTopicModel(false);
  };
  const handleCloseSec = () => {
    setOpenInterstModel(true);
    setShowTopicModel(false);
  };

  const handleClickInterest = (item) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(item.id)
        ? prevSelectedIds.filter((id) => id !== item.id)
        : [...prevSelectedIds, item.id]
    );
  };

  const handleSubmitInterestModel = (e) => {
    if (e) e.preventDefault();
    if (selectedIds.length > 0) {
      dispatch(postInterest(selectedIds));
      console.log("selectedIdsselectedIds",selectedIds);
      
      setShowTopicModel(true)
    }
    dispatch(getFollowYourInterest());
  };

  return (
    <div className="bg_home md:bg-[#F7F8FA] md:px-40 flex flex-col pb-14">
      <div className="flex flex-row md:my-5 md:mx-8">
        <div className="bg-[#F7F8FA] md:bg-[#ffffff] w-full md:rounded-xl shadow-sm p-3 md:p-5">
          <div className="flex flex-row items-center justify-between md:py-0">
            <div className="font-semibold text-[12px] mb-2 md:hidden">
              <PreSectionHeader title="Following" />
            </div>
            <h3 className="md:text-[#1A1A1A] md:cursor-pointer md:flex md:items-center md:gap-2 md:text-lg lg:text-2xl font-normal hidden">
              Topics
            </h3>
            {checkToken && (
              <button
                className="h-10 p-2 rounded-full flex gap-2 items-center justify-center cursor-pointer border bg-gray-200 w-1/3 md:w-32 md:bg-white"
                onClick={handleInterstModel}
              >
                <img
                  src={horizontalline}
                  alt="customize-icon"
                  className="h-[15px] w-[15px]"
                />
                <span>Customize</span>
              </button>
            )}
          </div>
          <hr className="border-b-1 md:border-none mt-3 md:mt-0 font-semibold" />
          <div className="my-2 md:hidden">Topics</div>
          <div className="flex flex-col gap-5 md:py-4 mb-2 overflow-y-auto max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-100px)]">
            <div className="flex flex-row overflow-x-auto gap-2 scrollbar-hide">
              {Array.isArray(followingData) && followingData.map((item) => (
                <div
                  key={item.id}
                  className={`rounded-xl shadow-sm w-24 cursor-pointer ${
                    clickedId === item.uuid
                      ? "border border-[#FF3D00] bg-[#ffffff]"
                      : "border border-[#F7F8FA] bg-[#ffffff] md:bg-[#F7F8FA]"
                  }`}
                  onClick={() => handleClick(item.uuid, "topics")}
                >
                  <div className="text-center flex flex-col items-center p-4 py-5">
                    <img
                      src={item?.topic?.icon?.[0]?.url}
                      alt="Icon"
                      className="w-auto h-10"
                    />
                    <div
                      className="pt-2 text-center max-w-full text-[12px] opacity-90 font-semibold leading-none text-gray-700"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {item?.topic?.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-[12px] text-[#7F8FA4] mt-6">
            Select leaders that'll appear in your For You news feed and under
            what you're Following
          </div>
          {checkToken ? (
            <></>
          ) : (
            <div className="bg-gray-100 rounded-[10px] flex items-center gap-2 px-4 py-2 my-2 w-fit">
              <FaSearch />
              <div className="h-[23px] w-[1px] bg-gray-300" />
              <input
                type="search"
                name="search"
                placeholder="Search Keyword"
                className="border-none outline-none text-xs bg-transparent rounded-md py-1 w-32"
                value={searchData}
                onChange={handleSearch}
              />
            </div>
          )}

          <div className="mt-2">
            <div className="text-[12px] font-semibold">You Liked</div>
            <div className="py-3">
              <div className="flex flex-row gap-5 flex-wrap">
                {checkToken
                  ? (isBorededSelectedTags || [])
                      .filter((ele) => ele?.isSelected)
                      .map((ele) => (
                        <div
                          key={ele?.id}
                          className="border rounded-full px-5 py-3 font-bold cursor-pointer bg-[#E2E7EE]"
                          onClick={() => handleClick(ele?.id, "subtopics")}
                        >
                          {ele?.name && (
                            <div className="text-[10px] text-gray-600">
                              # {ele?.name}
                            </div>
                          )}
                        </div>
                      ))
                  : (showTagsData || []).map((ele) => (
                      <div
                        key={ele?.id}
                        className={`border rounded-full px-5 py-3 font-bold cursor-pointer ${
                          selectedSubTagsByTopic[
                            selectedInterest?.topic?.id
                          ]?.includes(ele?.id)
                            ? "bg-[#E2E7EE]"
                            : "bg-transparent"
                        }`}
                        onClick={() => handleClick(ele?.id, "subtopics")}
                      >
                        {ele?.name && (
                          <div className="text-[10px] text-gray-600">
                            # {ele?.name}
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            </div>
          </div>
          {checkToken ?<></>:
          <div className="pt-10">
            <div className="flex flex-col items-center">
              <button
                type="button"
                className={`rounded-lg px-10 py-2 text-[14px] font-semibold px-24 bg-[#FF3D00] text-white ${
                  isButtonActive ? "" : "opacity-30 cursor-not-allowed"
                }`}
                onClick={handleSubmit}
                disabled={!isButtonActive}
              >
                Submit
              </button>
            </div>
          </div>
          }
        </div>
      </div>
      {openInterstModel && (
        <YourInteresteModel
          isOpen={openInterstModel}
          onClose={handleCloseFirst}
          showInterestData={showInterestData}
          submitText="Save & Next"
          handleClickInterest={handleClickInterest}
          selectedIds={selectedIds}
          isButtonActiveInterestModel={selectedIds.length >= 3}
          handleSubmitInterestModel={handleSubmitInterestModel}
        />
      )}
      {/* Uncomment and configure YourTopicModel as needed */}
      {showTopicModel && (
        <YourTopicModel
          isOpen={showTopicModel}
          onClose={handleCloseSec}
          // handleSubmit={handleSubmit}
    

          // Other props
        />
      )}
    </div>
  );
}

export default FollowingComp;
