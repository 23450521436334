import FollowingComp from "../../components/FollowingComp/FollowingComp";
import WithLayout from "../../components/Layout/Layout";

function Following() {
  return(

    <FollowingComp />
  )
}
Following.Layout = WithLayout;
export default Following;
