import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewToster } from "../../redux/actions/tosterAction";

export const Toaster = () => {
  const dispatch = useDispatch();

  const { showToaster } = useSelector((state) => state.toasterData);

  // console.log("showToaster", showToaster);

  useEffect(() => {
    let timer;
    if (showToaster?.type) {
      timer = setTimeout(() => {
        dispatch(viewToster({ type: "", message: "" }));
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [dispatch, showToaster]);

  return (
    <>
      {showToaster && showToaster?.type && (
        <div
          id="hide-toaster"
          className={`alert alert-success alert-dismissible fade show toaster-show ${showToaster?.type}`}
          role="alert"
        >
          {showToaster ? (
            <ul className="mb-0">
              {showToaster?.message && showToaster?.message.length ? (
                <li>{showToaster?.message}</li>
              ) : (
                ""
              )}
            </ul>
          ) : null}
          <button
            type="button"
            className="btn-close"
            style={{ backgroundImage: "/assets/model-close-icon.svg" }}
            data-bs-dismiss="alert"
            aria-label="btn-close"
            onClick={() => {
              dispatch(viewToster({ type: "", message: "" }));
            }}
          ></button>
        </div>
      )}
    </>
  );
};
