import { useState } from "react";
import shareicon from "../../assets/icons/Path 23100.svg";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import leftPredictionPerson from "../../assets/images/Group 26077.png";
import rightPredictionPerson from "../../assets/images/Group 26076.png";
function UsePollPredictionComp() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    // console.log("newValue", newValue);

    setValue(newValue);
  };
  return (
    <>
     

        {/* prediction  */}

        <div className="flex flex-row justify-center gap-9 py-10 my-10">
          <img src={leftPredictionPerson} alt="" />
          <span className="flex flex-col text-center justify-center">VS</span>
          <img src={rightPredictionPerson} alt="" />
        </div>

        <div className="flex flex-row justify-center">
          <Box
            sx={{
              width: 600,
              height: 20,
              position: "relative",
              backgroundColor: "gray",
              borderRadius: "0",
              paddingLeft: "2px",
              paddingRight: "2px",
            }}
          >
            <div style={{ position: "absolute", left: 0, bottom: 30 }}>
              {value < 0 ? Math.abs(value) : 0}{" "}
            </div>
            {/* Right Label */}
            <div style={{ position: "absolute", right: 0, bottom: 30 }}>
              {value > 0 ? value : 0}{" "}
            </div>
            <Slider
              aria-label="Custom Slider"
              value={value}
              onChange={handleChange}
              valueLabelDisplay="off"
              step={1}
              min={-100}
              max={100}
              sx={{
                "& .MuiSlider-thumb": {
                  width: 2,
                  height: 30,
                  // backgroundColor: 'red',
                  border: "1px solid #000",

                  borderRadius: "0",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#fff",
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#fff",
                },
              }}
            />
            {/* Left Label */}

            <div
              style={{
                position: "absolute",
                left: 0,
                top: 30,
                justifyContent: "start",
                textAlign: "start",
              }}
            >
              <div className="">Joe Biden</div>
              <div className="text-gray-500 text-xs">
                {value < 0 ? Math.abs(value) + "% of Vote" : 0}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: 50,
                transform: "translateX(-50%)",
                whiteSpace: "nowrap",
              }}
              className="text-gray-500 text-xs"
            >
              270 to win
            </div>
            {/* Right Label */}

            <div
              style={{
                position: "absolute",
                right: 0,
                top: 30,
                justifyContent: "end",
                textAlign: "end",
              }}
            >
              <div className="">Donald Trump</div>
              <div className="text-gray-500 text-xs">
                {" "}
                {value > 0 ? value + "% of Vote" : 0}
              </div>
            </div>
          </Box>
        </div>

        {/* prediction end */}
     
    </>
  );
}
export default UsePollPredictionComp;
